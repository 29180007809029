$white: #fff;
$sky-blue: #24AEE4;
$green: #42A948;
$red: #a94442;
$bg-red: #cc1814;
$highlight: rgba(0, 0, 0, 0.3);
$black: #020e05;
$light-purple-color:#E9D9EB;
$primary-purple-color: #7A539E;
$dark-purple-color:#65408D;
$primary-blue-color: #6dcce3;
$primary-red-color:#f05b5b;
$dark-blue-color:#121959;
@font-face {
    font-family: Logotype_Typeface;
    src: url(../assets/Logotype_Typeface/Qaranta_Bold.ttf);
}


/*------------------- Common CSS--------------------------------*/

body {
    height: 100%;
    background: #F2F6F8;
    margin: 0;
    font-family: "Helvetica";
    font-size: 14px;
}

.component-container {
    width: 100%;
    max-height: 100%;
}

.page-header {
    background-color: #FFF;
    margin-bottom: 10px;
    padding: 15px 10px 10px;
    box-shadow: 1px 1px 2px #00000029;
}

.main-content {
    width: 100%;
    margin: 0;
    padding: 0 15px;
}

.header-title {
    text-transform: uppercase;
    font-size: 18px;
    color: #121959;
    font-weight: 700;
    padding: 5px 10px;
    margin-bottom: 0;
}

.tabset-card {
    background-color: #fff;
    border: 1px solid #D9D9D9;
}

.breadcrumbs {
    float: right;
    font-size: 14px;
    font-weight: 600;
    color: $primary-purple-color;
    cursor: pointer;
    margin: 5px 5px 0 0;
}

.breadcrumb {
    margin-bottom: 0;
}

.breadcrumb-item.active {
    color: $primary-purple-color;
    color: #000
}

.card {
    box-shadow: 1px 1px 2px #00000029;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 15px;
}

.badge {
    font-size: inherit;
}

.downloadLink {
    font-size: 12px;
    text-decoration: none;
    color: darkgreen;
}

.downloadLink:before {
    content: "\f019";
    font-family: FontAwesome;
    font-size: 10px;
    text-decoration: none;
    margin-right: 5px;
    rotate: 90;
}

.downloadLink:hover {
    color: $dark-blue-color
}

.redirectlink {
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: $dark-purple-color;
}

.redirectlink:before {
    content: "\f0c1";
    font-family: FontAwesome;
    font-size: 10px;
    text-decoration: none;
    margin-right: 5px;
}

.redirectlink:hover {
    color: $dark-blue-color
}

.tooltip-inner {
    max-width: 500px !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
}


/*---------------------TAB SET CSS-------------------------*/


/*MAIN TAB SET*/

.nav-tabs {
    border: 0;
    display: inline-flex;
    text-align: center;
}

.nav-tabs .nav-item {
    margin-right: 30px;
    float: none;
    display: inline-block;
    display: inline;
    zoom: 1;
}

.nav-tabs .nav-item .nav-link {
    //color: #666;
    color: #778386;
    font: Helvetica;
    //border-bottom: 5px solid #dddddd;
    font-weight: 600;
    border-radius: 0;
    font-size: 12px;
    text-transform: uppercase;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
}

.nav-tabs .nav-item .nav-link.active {
    color: #7A539E;
    border-bottom: 3px solid $primary-purple-color;
    //background-color: rgba(124, 81, 160, 0.2);
    border-radius: 5px 5px 0 0;
}

.tab-content {
    padding: 2px 0px;
    text-align: left;
}

.nested-tab-content {
    padding: 0;
    text-align: left;
}


/*NESTED TAB SET*/

.nested-tabset .nav-item {
    margin-right: 20px;
}

.nested-tabset .nav-item .nav-link {
    color: #666;
    border-bottom: 3px solid #dddddd;
    font-weight: 600;
    border-radius: 10px;
    font-size: 14px;
    padding: 5px 15px;
    text-transform: capitalize;
    background-color: transparent;
}

.nested-tabset .nav-item .nav-link.active {
    border-radius: 5px;
}


/*---------------------Page & Section Headings------------------------*/

.section-heading {
    font-size: 17px;
    color: $dark-blue-color;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid #D6D6D6;
}

.section-subheading {
    text-transform: uppercase;
    font-size: 12px;
    color: $dark-blue-color;
    font-weight: 600;
    border-bottom: 2px solid #D6D6D6;
    padding: 5px;
}

.section-subheading1 {
    text-transform: uppercase;
    font-size: 12px;
    color: $primary-purple-color;
    font-weight: 600;
    border-bottom: 2px solid #AAA;
    padding: 5px;
}

.general-header {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.text-purple {
    color: $primary-purple-color;
}


/*---------------- FORM ELEMENTS CSS----------------------*/

.errormsg {
    font-size: 12px;
    color: #FF0000;
    text-align: left;
    padding: 5px;
}

.form-control {
    font-size: 14px;
    border-radius: 5px;
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 15%);
}

.form-control:disabled {
    border: none !important;
    background-color: transparent;
    font-size: 14px;
    padding: 0;
}

.ng-valid[required],
.ng-valid.required {
    border-left: 5px solid $green;
}

input.ng-invalid,
select.ng-invalid,
textarea.ng-invalid {
    border-left: 5px solid $red;
}

.form-group {
    padding: 4px 0;
    margin-bottom: 10px;
    position: relative;
    input::-webkit-input-placeholder {
        font-size: 14px;
        color: #AAA;
    }
    input:-moz-placeholder {
        font-size: 14px;
        color: #AAA;
    }
    input:-ms-input-placeholder {
        font-size: 14px;
        color: #AAA;
    }
    a.forgot-password {
        font-size: 14px;
        text-decoration: underline;
    }
}

label {
    font-size: 13px;
    font-weight: bold;
    margin: 5px 0 0 0;
    color: #839EA5;
}

small {
    font-size: 12px;
    text-transform: none;
}

.popover {
    max-width: 450px;
    width: 450px;
    left: 100px !important;
    border: 1px solid $light-purple-color;
}

.popover-arrow {
    left: -100px !important;
}

.quickAction .popover {
    left: 0 !important;
}

.popover-header {
    background-color: $light-purple-color;
    color: #666
}

.popover-body ol {
    padding: 5px;
}

.popover-body ol li {
    padding: 0 !important;
    margin: 0 !important;
}


/*----------------------BUTTON CSS--------------------------*/

.clickableIcon {
    cursor: pointer;
    margin-top: 5px;
    vertical-align: middle;
    //color: #008600; #629677
    font-size: 20px;
}

.clickableIcon:hover {
    transform: scale(1.1);
}

.btn-purple {
    background-color: $dark-purple-color;
    color: #FFF;
}

.btn-purple:hover {
    box-shadow: 0px 0px 15px $dark-purple-color;
    color: #FFF;
}

.btn-purple:active {
    background-color: $primary-purple-color !important;
    border: $primary-purple-color !important;
    box-shadow: 5px $primary-purple-color !important;
}

.btn-purple:disabled {
    background-color: $primary-purple-color;
    border: $primary-purple-color !important;
    opacity: 0.8;
}

.btn-purple-outline {
    border: 2px solid $dark-purple-color;
    border-radius: 4px;
    color: $dark-purple-color;
    font-weight: bold;
}

.btn-purple-outline:hover {
    box-shadow: 0px 0px 8px $dark-purple-color;
    color: $dark-purple-color;
}

.btn-purple-outline:active {
    background-color: $primary-purple-color !important;
    border: $primary-purple-color !important;
    box-shadow: 5px $primary-purple-color !important;
}

.btn-purple-outliine:disabled {
    background-color: #BBB;
    border: none !important;
    opacity: 0.8;
}

.btn-blue {
    color: #fff;
    background-color: $primary-blue-color;
}

.btn-blue:hover {
    color: #fff;
    box-shadow: 0px 0px 15px $primary-blue-color;
}

.btn-bulk {
    color: #fff;
    background-color: #4c79b3;
    border: none;
    padding: 6px;
    border-radius: 4px;
}

.btn-bulk:hover {
    color: #fff;
    box-shadow: 0px 0px 15px #4c79b3;
}

.btn-red {
    color: #FFF;
    background-color: $primary-red-color;
}

.btn-red:hover {
    color: #FFF;
    box-shadow: 0px 0px 15px $primary-red-color;
}

.btn-black {
    color: #fff;
    background-color: #020e05;
}

.btn-black:hover {
    color: #fff;
    box-shadow: 0px 0px 15px #000;
}

.clickableIcon {
    cursor: pointer;
}

.clickableIcon:hover {
    transform: scale(1.1);
}


/*----------------------------Data Table Custom CSS-------------------------------*/

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
    color: #666;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
}

table,
table.dataTable {
    border-collapse: separate;
    border-spacing: 2px;
    border: none !important;
    border-radius: 8px;
    vertical-align: middle;
    width: -webkit-fill-available !important;
    box-shadow: 1px 1px 2px #00000029;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: none;
    border-width: 0;
}

table tr {
    border: 1px solid #999 !important;
    border-collapse: collapse;
}

.table thead tr th {
    vertical-align: middle;
    background-color: $light-purple-color !important;
    color: $dark-blue-color;
    font-weight: 700;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    border-bottom: none;
}

.table>thead>tr th:first-child {
    border-radius: 8px 0 0 0;
}

.table>thead>tr th:not([style*="display:none"]):last-child {
    border-radius: 0 8px 0 0;
}

.table tbody td {
    padding: 5px 15px;
    font-size: 12px;
    text-align: center;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: #CCC;
    box-shadow: 1px 1px 3px #000;
}

.dataTables_scrollBody thead {
    visibility: collapse;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: none;
}


/*----------------------------Modal CSS----------------------------------*/

@media (min-width: 576px) {
    .modal-xl {
        max-width: 500px;
    }
}

@media (min-width: 768px) {
    .modal-xl {
        max-width: 700px;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        max-width: 900px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1450px;
    }
}

.modal-dialog {
    min-width: 400px;
}

.modal-header {
    padding: 5px 10px;
}

.modal-title {
    margin-bottom: 0;
    color: $dark-blue-color;
    font-weight: 600;
}

.modal-close {
    font-size: 25px;
    font-weight: 600;
    background: transparent;
    border: none;
    color: $primary-red-color;
    padding: 0;
}

.modal-body {
    padding: 10px;
}

.btn-section {
    border-top: 2px solid #BBB;
    padding: 10px 0 0 0;
    margin-top: 10px;
    button {
        margin-left: 10px;
    }
}


/*-------------------- Angular2 MultiSelect Drop Down--------------------------*/

//MultiSelect DropDown
.selected-list .c-list .c-token {
    background: #999 !important;
    height: 20px;
}

.pure-checkbox input[type="checkbox"]:checked+label:before {
    background: #999 !important;
}

.pure-checkbox input[type=checkbox]+label:before {
    margin-top: -9px !important;
    margin-left: 1px !important;
}

.pure-checkbox input[type="checkbox"]+label:before {
    border: 1px solid #9e9e9e !important;
    border: 2px solid #666 !important;
}

.dropdown-list ul {
    overflow: hidden !important;
}

.dropdown-list ul li:last-child {
    padding-bottom: 0px !important;
}

.dropdown-list ul li:last-child {
    padding-bottom: 0 !important;
}

.dropdown-list ul li:first-child {
    padding-top: 0 !important;
}

.dropdown-list ul li {
    padding: 0 10px !important;
}

.lazyContainer {
    margin-left: 10px !important;
    font-size: 14px !important
}

.selected-list .c-btn {
    background: #fff !important;
    border: 1px solid #ccc !important;
}

.selected-list .c-btn {
    min-height: 30px;
    height: 30px;
    font-size: 12px;
    padding: 2px 7px !important;
}


/* Quick Action Button Drawer*/

.quickAction,
.menu {
    display: flex;
    align-items: center;
}

.quickAction {
    position: fixed;
    background-color: #FFF; //#BCC5C7;//#70877F;
    transition: 0.5s;
    border-radius: 50px;
    // overflow: hidden;
    box-shadow: 0 8px 15px rgba(0, 0, 0, .2);
    width: max-content;
    height: 50px;
    z-index: 1000;
    right: 0;
    border: 1px solid $dark-purple-color;
}

.quickAction input {
    width: 25px;
    height: 40px;
    cursor: pointer;
    opacity: 0;
}

.quickAction span {
    position: absolute;
    left: 12px;
    width: 25px;
    height: 4px;
    border-radius: 50px;
    background-color: $dark-purple-color;
    pointer-events: none;
    transition: 0.5s;
}

.quickAction .menu {
    margin: 0;
    padding: 0;
    width: 0;
    overflow: hidden;
    transition: 0.5s;
}

.quickAction input:checked~.menu {
    width: 280px;
}

.quickAction .menu li {
    list-style: none;
    margin: 0 14px;
    border-radius: 10px;
    height: 40px;
    padding: 0 10px;
    padding-top: 1%;
}

.menu li .clickableIcon {
    color: $dark-purple-color;
    font-size: 24px;
}

.quickAction .menu li.disabled .clickableIcon {
    color: #DDD;
    cursor: not-allowed;
}

.quickAction .menu li:not(.disabled):hover {
    //color: #FFF;
    background-color: $light-purple-color;
}

.quickAction input:checked~span {
    background-color: #f974a1;
}

.quickAction span:nth-child(2) {
    transform: translateY(-8px);
}

.quickAction input:checked~span:nth-child(2) {
    transform: translateY(0) rotate(-45deg);
}

.quickAction span:nth-child(3) {
    transform: translateY(8px);
}

.quickAction input:checked~span:nth-child(3) {
    transform: translateY(0) rotate(45deg);
}

.quickAction input:checked~span:nth-child(4) {
    display: none;
}


/* Scroll bar for css */


/* width */

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #888;
    ;
}





/* You can add global styles to this file, and also import other style files */